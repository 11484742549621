<template>
  <ul>
    <li
      v-for="item in items"
      :key="item.id"
      class="bfy-level"
    >
      <div class="bfy-level-left">
        <car-list-item :item="item" />
        <small class="tag is-abbr-like">
          {{ item.model | firstWord }}
        </small>
      </div>
      <div class="bfy-level-right">
        <b-button type="is-info" size="is-small is-circle" icon-left="chevron-right"
                  outlined
                  @click="showList(item.id)"
        />
      </div>
    </li>
  </ul>
</template>
<script>
import CarListItem from '@/components/Board/CarListItem'

export default {
  name: 'CarList',
  components: { CarListItem },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    showList (carId) {
      this.$parent.$parent.showTransferList(null, carId)
    }
  }
}
</script>
